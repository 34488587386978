export const INGREDIENT_CATEGORIES = [
  'Vegetables & Fruits',
  'Dry Staples',
  'Dairy and Dairy Alternatives',
  'Processed Ingredients',
  'Bakery and Bread',
  'Oils and Fats',
  'Beverages and Additives',
  'Frozen and Refrigerated Items',
  'Specialty Ingredients',
  'Packaging and Garnishes',
  'Miscellaneous'
];

export const INGREDIENT_UNITS = [
  'kg',
  'g',
  'L',
  'ml',
  'pcs',
  'dozen',
  'pack',
  'box'
];
