export const RECIPE_CATEGORIES = [
  'Uncategorized',  // Added for recipes without categories
  'Beverages',
  'Coffee',
  'Breakfast Items',
  'Sandwiches & Wraps',
  'Salads & Soups',
  'Main Courses',
  'Sides',
  'Desserts',
  'Snacks',
  'Specials',
  "Kids' Menu",
  'Combo Meals',
  'Bakery'
];

export const INGREDIENT_CATEGORIES = [
    'Raw Ingredients',
    'Dairy and Dairy Alternatives',
    'Specialty Ingredients',
    'Frozen and Refrigerated Items',
    'Oils and Fats',
    'Dry Staples'
];
